import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SearchBarOverlay from "./search-bar-overlay";
import OmiLogo from "../assets/logos/omi_seattle_logo_white.svg";

const Bar = styled.nav`
  height: 85px;
  background-color: #111111;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 20px;
  @media (max-width: 768px) {
    visibility: hidden;
    height: 0px;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const Logo = styled.img`
  height: 30px;
`;

const MenuList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  margin: 0 15px;
  margin-top: 25px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const MenuLink = styled(Link)`
  font-family: "PPNeueMontreal-Book" !;
  letter-spacing: 0.7px;
  text-decoration: none;
  color: white;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

const leftMenuItems = [
  { name: "MENSWEAR", path: "/items-men" },
  { name: "WOMENSWEAR", path: "/items-women" },
  { name: "SALE", path: "/sale" },
];
const rightMenuItems = ["LOGIN", "WISHLIST", "BAG (0)"];

const MenuBar = ({ setSearchTerm }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearchVisible(true); // Open the search overlay
  };

  const handleSearchSubmit = (searchTerm) => {
    if (location.pathname !== "/items") {
      navigate("/items", { state: { searchTerm } });
    } else {
      setSearchTerm(searchTerm);
    }
    setIsSearchVisible(false); // Close search bar after submitting
  };

  return (
    <>
      <Bar>
        {/* Left-aligned menu items */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <MenuList>
            {leftMenuItems.map((item, index) => (
              <MenuItem key={index}>
                {item.name === "MENSWEAR" || item.name === "WOMENSWEAR" ? (
                  <MenuLink to={item.path}>{item.name}</MenuLink>
                ) : (
                  <MenuLink to="#" onClick={(e) => e.preventDefault()}>
                    {item.name}
                  </MenuLink>
                )}
              </MenuItem>
            ))}

            {/* SEARCH link still opens the search functionality */}
            <MenuItem>
              <MenuLink to="/" onClick={handleSearch}>
                SEARCH
              </MenuLink>
            </MenuItem>
          </MenuList>
        </div>

        {/* Centered logo */}
        <LogoWrapper>
          <a href="/">
            <Logo src={OmiLogo} alt="Omi Logo" />
          </a>
        </LogoWrapper>

        {/* Right-aligned menu items */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <MenuList>
            {rightMenuItems.map((item, index) => (
              <MenuItem key={index}>
                {/* This link does nothing */}
                <MenuLink to="#" onClick={(e) => e.preventDefault()}>
                  {item}
                </MenuLink>
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </Bar>

      {/* Search Overlay */}
      {isSearchVisible && (
        <SearchBarOverlay
          onClose={() => setIsSearchVisible(false)}
          onSearch={handleSearchSubmit}
        />
      )}
    </>
  );
};

export default MenuBar;
