import React from "react";
import styled from "styled-components";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// Container for the page indicator bar
const BarContainer = styled.div`
  height: 80px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Wrapper for the page indicator
const PageIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px; /* Space between elements */
`;

// Arrow buttons
const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #333;
  display: flex;
  align-items: center;

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;

// Page numbers
const PageNumber = styled.span`
  font-size: 16px;
  color: ${(props) => (props.isCurrent ? "black" : "grey")};
  font-weight: ${(props) => (props.isCurrent ? "bold" : "normal")};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const PageIndicator = ({ totalPages, currentPage, onPageChange }) => {
  return (
    <BarContainer>
      <PageIndicatorContainer>
        {/* Left Arrow */}
        <ArrowButton
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FaChevronLeft />
        </ArrowButton>

        {/* Page Numbers */}
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <PageNumber
            key={pageNumber + 1}
            isCurrent={pageNumber + 1 === currentPage}
            onClick={() => onPageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </PageNumber>
        ))}

        {/* Right Arrow */}
        <ArrowButton
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FaChevronRight />
        </ArrowButton>
      </PageIndicatorContainer>
    </BarContainer>
  );
};

export default PageIndicator;
