// src/services/strapiService.js
import axios from "axios";

const API_URL = "https://strapi-production-7b58.up.railway.app/api";

export const fetchHeroImage = async () => {
  try {
    const response = await axios.get(`${API_URL}/hero-image?populate=*`); // Populate the image field
    return response.data.data; // Strapi returns the content under data.data
  } catch (error) {
    console.error("Error fetching hero image from Strapi:", error);
    throw error;
  }
};

//ITEMS
//*****************************************************************************************************************
export const fetchClothingItems = async () => {
  try {
    const response = await axios.get(
      "https://strapi-production-7b58.up.railway.app/api/clothing-items",
      {
        params: {
          populate: "*",
          "pagination[pageSize]": 1000, // Adjust this number if needed
          "pagination[page]": 1, // Start from the first page
        },
      }
    );
    return response.data.data; // Return the data directly
  } catch (error) {
    console.error("Error fetching clothing items:", error);
    throw error; // Propagate the error for handling upstream
  }
};

//SCROLLER
//*****************************************************************************************************************
export const fetchScrollerOne = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/scroller-one?populate=clothing_items`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching scroller-one data:", error);
    return null;
  }
};
