import "react-chat-elements/dist/main.css";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axios from "axios"; // Import axios for making API requests
import MessageItem from "./chat/message";
import ChatHorizontalScroll from "./chat/horizontal-scroller";
import omiLogo from "../assets/logos/omi_seattle_logo_black.png";
import omiLogoWhite from "../assets/logos/omi_logo_white.png";
import SendIcon from "../assets/chat/send.png";
import AddImageIcon from "../assets/chat/add.png";
import CloseIcon from "../assets/chat/close.svg";

// Styled-components for ChatComponent
const ChatContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9999;
`;

const ChatButton = styled.button`
  width: 60px;
  height: 60px;
  background-color: black !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: right 0.3s;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
`;

const ChatIcon = styled.img`
  width: 70px;
  height: 30px;
`;

const ChatWindow = styled.div`
  width: 350px;
  height: 600px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px); /* Adjust the blur value as needed */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 20px;
  right: 100px;
`;

const OmiLogoContainer = styled.div`
  text-align: left;
  padding-left: 10px;
`;

const OmiLogo = styled.img`
  height: 25px;
  cursor: pointer;
`;

const Hr = styled.hr`
  color: grey;
  border: none;
  height: 1px;
  margin: 0;
`;

const MessageList = styled.div`
  height: calc(110% - 165px);
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  overflow-y: auto;
  margin-top: 5px;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Fading effect */
  mask-image: linear-gradient(
    to bottom,
    transparent,
    black 3%,
    black 97%,
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    black 3%,
    black 97%,
    transparent
  );
`;

const ImageMessage = styled.img`
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
  border-radius: 10px;
  position: relative;
  left: -90px;
`;

const textareaStyle = {
  resize: "none",
  overflow: "hidden",
  boxSizing: "border-box",
  height: "auto",
  lineHeight: "1.5em",
  backgroundColor: "white",
  width: "100%",
  border: "none",
  borderRadius: "10px",
  height: "45px",
  padding: "0.8em", // Add some padding for better centering
  paddingLeft: "40px",
  paddingRight: "40px" /* avoid text under icon */,
  maxHeight: "calc(1.5em * 2)", // Maximum height for two lines
  whiteSpace: "pre-wrap", // Preserve whitespace and allow line breaks
  outline: "none",
  fontFamily: " Arial, sans-serif",
};

const UploadButton = styled.button`
  position: absolute;
  left: 7px;
  top: 47%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const UploadIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const SendButton = styled.button`
  position: absolute;
  right: 0px;
  top: 47%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1;
`;

const SendIconImage = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  transform: translate(-120%, -50%);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px; // Adjust this value as needed
  right: 10px; // Adjust this value as needed
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1; // Make sure it appears above other elements
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  resize: none;
  overflow: hidden;
  box-sizing: border-box;
  line-height: 1.5em;
  background-color: white;
  border: none;
  border-radius: 10px;
  height: 45px;
  padding: 0.8em;
  padding-left: 40px;
  padding-right: 40px;
  max-height: calc(1.5em * 2);
  white-space: pre-wrap;
  outline: none;
  font-family: Arial, sans-serif;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 25px;
`;

const ChatComponent = () => {
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const inputRef = useRef(null);
  const messageListRef = useRef(null);

  useEffect(() => {
    const storedMessages =
      JSON.parse(localStorage.getItem("chatMessages")) || [];
    setMessages([...storedMessages]);
  }, []);

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  const clearMessages = () => {
    localStorage.removeItem("chatMessages");
    setMessages([]);
  };

  const sendMessage = async () => {
    const message = inputRef.current.value.trim();

    if (message !== "") {
      const userMessage = {
        type: "text",
        position: "right", // User message should be on the right
        text: message,
        date: new Date(),
      };

      setMessages((prevMessages) => [...prevMessages, userMessage]);

      inputRef.current.value = "";

      try {
        const response = await axios.put(
          "https://o8i67nwtz3.execute-api.us-east-1.amazonaws.com/prod",
          { prompt: message },
          {
            headers: {
              "x-api-key": "0x02zu9vop3LBkRW4DUytIN1DXkmW87aKLiXctH7",
              "Content-Type": "application/json",
            },
          }
        );

        let parsedBody = response.data.body;

        // If body is a string, parse it
        if (typeof parsedBody === "string") {
          parsedBody = JSON.parse(parsedBody);
        }

        if (parsedBody.products && parsedBody.products.length > 0) {
          const productCards = parsedBody.products.map((product) => ({
            id: product.id,
            image: product.metadata.img_url,
            name: product.metadata.title,
            brand: product.metadata.brand, // Customize this
            price: `$${product.metadata.price.toLocaleString()}`,
          }));

          const collectionMessage = {
            type: "collection",
            position: "left", // API response should be on the left
            cards: productCards,
            date: new Date(),
          };

          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, collectionMessage];
            localStorage.setItem(
              "chatMessages",
              JSON.stringify(updatedMessages)
            );
            return updatedMessages;
          });
        } else {
          const apiMessage = {
            type: "text",
            position: "left", // API response should be on the left
            text:
              typeof parsedBody === "object"
                ? JSON.stringify(parsedBody, null, 2) // Convert object to a string
                : parsedBody,
            date: new Date(),
          };

          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, apiMessage];
            localStorage.setItem(
              "chatMessages",
              JSON.stringify(updatedMessages)
            );
            return updatedMessages;
          });
        }
      } catch (error) {
        console.error("Error sending message to API:", error);
        const errorMessage = {
          type: "text",
          position: "left", // Error message from API should also be on the left
          text: "Error: Could not send message",
          date: new Date(),
        };

        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages, errorMessage];
          localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
          return updatedMessages;
        });
      }
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newMessage = {
          type: "image",
          position: "left", // User image on the left
          image: e.target.result,
          date: new Date(),
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        localStorage.setItem(
          "chatMessages",
          JSON.stringify([...messages, newMessage])
        );
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <ChatContainer>
      <ChatButton onClick={toggleChat}>
        <ChatIcon src={omiLogoWhite} alt="Chat Icon" />
      </ChatButton>
      {showChat && (
        <ChatWindow>
          <CloseButton onClick={toggleChat}>
            <img src={CloseIcon} alt="Close chat" />
          </CloseButton>
          <OmiLogoContainer>
            <OmiLogo
              src={omiLogo}
              alt="Stylist Title"
              onClick={clearMessages}
            />
          </OmiLogoContainer>
          <Hr />
          <MessageList ref={messageListRef}>
            {messages.map((message, index) =>
              message.type === "collection" ? (
                <ChatHorizontalScroll key={index} cards={message.cards} />
              ) : message.type === "image" ? (
                <ImageMessage
                  key={index}
                  src={message.image}
                  alt="Sent Image"
                />
              ) : (
                <MessageItem key={index} message={message} />
              )
            )}
          </MessageList>
          <InputContainer>
            <InputWrapper>
              <UploadButton
                onClick={() => document.getElementById("imageUpload").click()}
              >
                <UploadIcon src={AddImageIcon} alt="Upload image" />
              </UploadButton>
              <StyledTextarea
                ref={inputRef}
                placeholder="What are you looking for today..."
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    sendMessage();
                  }
                }}
              />
              <SendButton onClick={sendMessage}>
                <SendIconImage src={SendIcon} alt="Send message icon" />
              </SendButton>
            </InputWrapper>
          </InputContainer>
          <input
            id="imageUpload"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
        </ChatWindow>
      )}
    </ChatContainer>
  );
};

export default ChatComponent;
