import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 1500px; /* Prevent the container from exceeding 1500px */
  margin: 0 auto; /* Center the container */
  padding: 0 50px; /* Maintain 50px padding on both sides */
`;

const Container = styled.div`
  display: flex;
  max-width: 800px; /* Set max-width for the content inside the container */
  margin-left: 0; /* No additional left margin needed here */
`;

const ImageContainer = styled.div`
  flex: 1;
  margin-right: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 1500 / 2000;
  object-fit: cover;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Subtitle = styled.div`
  margin-bottom: 25px;
`;

const SmallImagesLayout = () => {
  return (
    <Wrapper>
      <Container>
        <ImageContainer>
          <Image src="https://via.placeholder.com/300x450" alt="Image 1" />
          <Title>CASUAL SHIRTS: ONIA AND MORE</Title>
          <Subtitle>Shop now</Subtitle>
        </ImageContainer>
        <ImageContainer>
          <Image src="https://via.placeholder.com/300x450" alt="Image 2" />
          <Title>TIMELESS T-SHIRTS FOR THIS SEASON AND BEYOND</Title>
          <Subtitle>Shop now</Subtitle>
        </ImageContainer>
      </Container>
    </Wrapper>
  );
};

export default SmallImagesLayout;
