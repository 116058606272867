import React, { useState, useEffect } from "react";
import styled from "styled-components";
import OmiLogo from "../assets/logos/omi_seattle_logo_white_big.png";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const MessageBox = styled.div`
  width: 600px;
  height: 200px;
  background-color: #111111;
  padding: 50px;
  color: white;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const Logo = styled.img`
  height: 40px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const MessageText = styled.p`
  margin-top: 70px; /* To give space below the logo */
  font-size: 22px;
`;

const Disclaimer = () => {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    setShowMessage(true);
  }, []);

  const handleClose = () => {
    setShowMessage(false);
  };

  return (
    <>
      {showMessage && (
        <Overlay onClick={handleClose}>
          <MessageBox>
            <Logo src={OmiLogo} alt="Omi Logo" />
            <MessageText>
              This is a mock website created for demonstration purposes only.
              Users cannot purchase any items through this site. The purpose of
              this website is to provide an understanding of Omi's capabilities
              and how it can help retailers and brands engage with their
              customers in new and innovative ways.
            </MessageText>
          </MessageBox>
        </Overlay>
      )}
    </>
  );
};

export default Disclaimer;
