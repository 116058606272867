import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";

// Styles for individual item cards
const Card = styled.div`
  padding: 10px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1.2; /* Enforces a vertical rectangle aspect ratio */
  overflow: hidden;
  position: relative;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isHovered ? 1 : 0)};
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  letter-spacing: 1px;
  margin: 20px 0 5px 0;
  cursor: pointer;
`;

const ItemSubtitle = styled.p`
  font-family: TimesNow;
  font-size: 16px;
  color: #777;
  margin: 0px;
`;

const ItemPrice = styled.p`
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
  color: black;
`;

const ProductLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
`;

const ItemCard = ({ images, name, subtitle, price, description, id }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  // Use the first image as default and the second image on hover
  const mainImage = images?.[0] || "https://via.placeholder.com/200x300";
  const hoverImage = images?.[1] || mainImage;

  // Handler to navigate to item details, passing the description
  const handleNavigation = () => {
    navigate(`/item-details/${id}`, {
      state: { id, name, subtitle, price, images, description }, // Passing data via state
    });
  };

  return (
    <Card onClick={handleNavigation}>
      <ImageContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ItemImage src={mainImage} alt={name} isHovered={!isHovered} />
        <ItemImage src={hoverImage} alt={name} isHovered={isHovered} />
      </ImageContainer>
      <ItemTitle>{name}</ItemTitle>
      <ItemSubtitle>{subtitle}</ItemSubtitle>
      <ItemPrice>{price}</ItemPrice>
    </Card>
  );
};

export default ItemCard;
