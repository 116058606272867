import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import FooterNewsletter from "../components/footer-newsletter";
import { useItemsData } from "../providers/DataContext";
import MenuBar from "../components/menu-bar";

// Reusing the UI components
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 2rem;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1125px;
  width: 100%;
  padding: 2rem;
  background-color: white;
`;

const ImageWrapper = styled.div`
  flex: 0 0 40%;
  margin-right: 2rem;
  img {
    max-width: 100%;
    height: auto;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    text-align: left;
  }
  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: left;
    color: #555;
  }
  p {
    margin-bottom: 1rem;
    text-align: left;
  }
  .price {
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: left;
  }
`;

const SizeOptions = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1rem;
  button {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: ${(props) => (props.selected ? "#f0f0f0" : "white")};
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 2rem;
  button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    &:hover {
      background-color: #333;
    }
  }
`;

const EditorsNotes = styled.div`
  margin-top: 2rem;
  text-align: left;
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }
  p {
    text-align: left;
  }
`;

const ItemDetailsPage = () => {
  const { id } = useParams();
  const items = useItemsData();
  const [item, setItem] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  useEffect(() => {
    const foundItem = items.find((item) => item.id === parseInt(id, 10));
    if (foundItem) {
      setItem(foundItem);
    }
  }, [id, items]);

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  if (!item) {
    return (
      <CenteredContainer>
        <p>Loading...</p>
      </CenteredContainer>
    );
  }

  return (
    <PageWrapper>
      <MenuBar />
      <CenteredContainer>
        <Container>
          <ImageWrapper>
            <img src={item.images[0]} alt={item.title} />
          </ImageWrapper>
          <ContentWrapper>
            <h2>{item.brand}</h2>
            <h3>{item.title}</h3>
            <p className="price">${item.price}</p>
            <SizeOptions>
              {["XS", "S", "M", "L", "XL"].map((size) => (
                <button
                  key={size}
                  onClick={() => handleSizeSelect(size)}
                  selected={selectedSize === size}
                >
                  {size}
                </button>
              ))}
            </SizeOptions>
            <ActionButtons>
              <button className="add-to-bag">Add to Bag</button>
              <button className="add-to-wishlist">Add to Wishlist</button>
            </ActionButtons>
            <EditorsNotes>
              <label>Editors Notes</label>
              <p>{item.description}</p>
            </EditorsNotes>
          </ContentWrapper>
        </Container>
      </CenteredContainer>
      <FooterNewsletter />
    </PageWrapper>
  );
};

export default ItemDetailsPage;
