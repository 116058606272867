import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Slide animations
const slideInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(20%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOutRight = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(-20%);
  }
`;

const fadeOutLeft = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(20%);
  }
`;

const CarouselContainer = styled.div`
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ProductContainer = styled.div`
  display: flex;
  ${(props) =>
    props.animate &&
    css`
      animation: ${props.direction === "right" ? slideInRight : slideInLeft}
        0.5s ease;
    `}
`;

const ProductItem = styled.div`
  flex: 0 0 200px; /* Fixed width for each product */
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  opacity: ${(props) => (props.fadingOut ? 0 : 1)};
  transition: opacity 0.4s ease, transform 0.4s ease;
  ${(props) =>
    props.fadingOut &&
    css`
      animation: ${props.direction === "right" ? fadeOutRight : fadeOutLeft}
        0.4s ease;
      animation-delay: ${props.delay}s;
    `}
`;

const ProductImage = styled.div`
  width: 200px;
  height: 270px;
  background-color: #d0d0d0;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.url});
`;

const ProductBrand = styled.p`
  font-family: PPNeueMontreal-Medium!;
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: 0.7px;
`;

const NavButton = styled.button`
  position: absolute;
  top: 37%;
  background: rgba(0, 0, 0, 0.5);
  color: grey;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 1;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  &:disabled {
    display: none; /* Hide the button when disabled */
  }

  &.left {
    left: 10px;
    color: white;
  }

  &.right {
    right: 10px;
    color: white;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  text-align: center;
  margin-bottom: 20px;
`;

const Count = styled.p`
  text-align: center;
  margin-bottom: 20px;
`;

const ProductCarousel = ({ dataEndpoint }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4); // Default for desktop
  const [fadingOut, setFadingOut] = useState(false);
  const [direction, setDirection] = useState("right");
  const [carouselData, setCarouselData] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // Single declaration

  // Fetch data from the provided endpoint
  const fetchCarouselData = async () => {
    try {
      const response = await axios.get(dataEndpoint);
      if (
        response.data &&
        response.data.data &&
        response.data.data.attributes
      ) {
        setCarouselData(response.data.data);
      } else {
        setError("Unexpected data structure in API response");
      }
    } catch (error) {
      console.error("Error fetching carousel data:", error);
      setError("Failed to fetch carousel data");
    }
  };

  useEffect(() => {
    fetchCarouselData();
  }, [dataEndpoint]); // Re-fetch data if the endpoint changes

  useEffect(() => {
    const updateItemsPerPage = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
        setItemsPerPage(2); // Show 2 items on mobile screens
      } else {
        setItemsPerPage(4); // Show 4 items on larger screens
      }
    };

    updateItemsPerPage(); // Set initial items per page
    window.addEventListener("resize", updateItemsPerPage); // Update on resize
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  const clothingItems = carouselData?.attributes?.clothing_items?.data || [];

  const visibleItems = clothingItems.slice(
    currentIndex,
    currentIndex + itemsPerPage
  );

  const itemsToShow =
    visibleItems.length < itemsPerPage
      ? [
          ...visibleItems,
          ...clothingItems.slice(0, itemsPerPage - visibleItems.length),
        ]
      : visibleItems;

  const nextSlide = () => {
    if (currentIndex + itemsPerPage < clothingItems.length) {
      setDirection("right"); // Set direction to "right"
      setFadingOut(true);
      setTimeout(() => {
        setFadingOut(false);
        setCurrentIndex((prevIndex) => prevIndex + itemsPerPage);
      }, 400); // Reset animation after transition
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setDirection("left"); // Set direction to "left"
      setFadingOut(true);
      setTimeout(() => {
        setFadingOut(false);
        setCurrentIndex((prevIndex) => prevIndex - itemsPerPage);
      }, 400); // Reset animation after transition
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!carouselData) {
    return <div>Loading...</div>;
  }

  if (clothingItems.length === 0) {
    return (
      <div>
        <Title>{carouselData.attributes.title}</Title>
        <Subtitle>{carouselData.attributes.subtitle}</Subtitle>
        <Count>Count: {carouselData.attributes.count}</Count>
        <p>No items to display</p>
      </div>
    );
  }

  return (
    <div>
      <CarouselContainer>
        <NavButton
          onClick={prevSlide}
          className="left"
          disabled={currentIndex === 0}
        >
          &lt;
        </NavButton>
        <ProductContainer animate={!fadingOut} direction={direction}>
          {itemsToShow.map((item, index) => {
            const imageUrl = item.attributes.images?.data[0]?.attributes?.url;
            const handleItemClick = (item) => {
              navigate(`/item-details/${item.id}`, {
                state: {
                  id: item.id,
                  name: item.attributes.name,
                  subtitle: item.attributes.subtitle,
                  price: item.attributes.price,
                  images: item.attributes.images,
                  description: item.attributes.description,
                },
              });
            };
            return (
              <ProductItem
                key={item.id}
                fadingOut={fadingOut}
                direction={direction}
                delay={
                  fadingOut ? index * 0.1 : (itemsPerPage - index - 1) * 0.1
                } // Handle fade-out and fade-in order correctly
                onClick={() => handleItemClick(item)}
              >
                <ProductImage url={imageUrl} />
                <ProductBrand>{item.attributes.brand}</ProductBrand>
              </ProductItem>
            );
          })}
        </ProductContainer>
        <NavButton
          onClick={nextSlide}
          className="right"
          disabled={currentIndex + itemsPerPage >= clothingItems.length}
        >
          &gt;
        </NavButton>
      </CarouselContainer>
    </div>
  );
};

export default ProductCarousel;
