import React from "react";
import HeroImage from "../components/hero-image";
import OfferSection from "../components/offer-section";
import OfferSectionWomen from "../components/offer-section-women";
import TwoImagesLayout from "../components/two-images-layout";
import SmallImagesLayout from "../components/small-images-layout";
import Separator from "../components/separator";
import JournalEntry from "../components/journal-entry";
import SixColumnLayout from "../components/six-column-layout";
import ThreeColumnLayout from "../components/three-column-layout";
import FooterNewsletter from "../components/footer-newsletter";
import Footer from "../components/footer";
import Disclaimer from "../components/disclaimer";
import MenuBarAlternative from "../components/menu-bar";

function HomePage() {
  return (
    <>
      <Disclaimer />
      <MenuBarAlternative />
      <HeroImage />
      <OfferSection />
      <Separator />
      <TwoImagesLayout />
      <Separator />
      <OfferSectionWomen />
      <Separator />
      {/* <SmallImagesLayout /> */}
      {/* <Separator /> */}
      <JournalEntry />
      <Separator />
      <SixColumnLayout />
      <Separator />
      <ThreeColumnLayout />
      <FooterNewsletter />
      {/* <Footer /> */}
    </>
  );
}

export default HomePage;
