import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import MenuBarAlternative from "../components/menu-bar"; // New component
import ItemCard from "../components/item-card";
import FooterNewsletter from "../components/footer-newsletter";
import Footer from "../components/footer";
import { useItemsData } from "../providers/DataContext";
import PageIndicator from "../components/page-indicator";
import FilterMenu from "../components/filter-menu";
import Separator2 from "../components/separator2";

const Container = styled.div`
  display: flex;
  padding: 20px;
  max-width: 1600px;
  margin: 50px auto;
`;

const LeftMenu = styled.div`
  width: 400px;
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
`;

const RightContent = styled.div`
  flex-grow: 1;
`;

const ItemsGrid = styled.div`
  display: grid;
  gap: 20px;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 999px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CenteredTitle = styled.div`
  font-family: TimesNow-Book;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 40px;
  font-size: 24px;
  line-height: 26px;
`;

const CenteredText = styled.div`
  font-family: TimesNow-Book;
  text-align: center;
  margin: 0 auto 30px auto; /* Centering with auto margins */
  max-width: 750px; /* Control the maximum width */
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.15px;
`;

const ItemsPage = ({ gender }) => {
  const { state } = useLocation();
  const items = useItemsData();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // Load 27 items per page
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (state?.searchTerm) {
      setSearchTerm(state.searchTerm);
    }
    return () => {
      setSearchTerm("");
    };
  }, [state]);

  useEffect(() => {
    const searchedItems = searchTerm
      ? items.filter((item) =>
          Object.values(item).some(
            (value) =>
              value != null &&
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      : items;

    const filtered = searchedItems.filter((item) => {
      return gender === null || item.gender === gender || item.gender === null;
    });

    setFilteredItems(filtered);
  }, [items, searchTerm, gender]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemClick = (item) => {
    navigate(`/item-details/${item.id}`);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1); // Reset to first page when searching
  };

  return (
    <>
      {/* Replace HeaderBar and MenuBar with MenuBarAlternative */}
      <MenuBarAlternative setSearchTerm={handleSearch} />
      <CenteredTitle>Clothing</CenteredTitle>
      <CenteredText>
        {gender === "male"
          ? `Build your new-season wardrobe with our edit of the latest designer
        menswear, from T-shirts, shorts, polo shirts, lightweight jackets, and
        smart tailoring to casual shirts, pants, and denim. Don’t forget to
        check out our shoe selection too, where you’ll find loafers,
        espadrilles, sneakers, and more.`
          : `Curate your dream wardrobe with our expert selection of designer clothing. Whether you’re searching for dresses for a special occasion or in need of casual jeans and weekend wear, we’ve got you covered. Discover the latest collections from your favorite brands here.`}
      </CenteredText>
      <Separator2 />
      <Container>
        <LeftMenu>
          <FilterMenu />
        </LeftMenu>
        <RightContent>
          <ItemsGrid>
            {currentItems.map((item) => (
              <ItemCard
                key={item.id}
                id={item.id}
                images={item.images}
                name={item.brand}
                subtitle={item.title}
                price={`$${item.price}`}
                description={item.description}
                onClick={() => handleItemClick(item)}
              />
            ))}
          </ItemsGrid>
        </RightContent>
      </Container>
      <PageIndicator
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
      <FooterNewsletter />
    </>
  );
};

export default ItemsPage;
