import "./App.css";
import { DataProvider } from "./providers/DataContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StylistChat from "./components/stylist-chat";
import "react-chat-elements/dist/main.css";

// routes
import HomePage from "./pages/homepage";
import ItemsPage from "./pages/items-page";
import ItemDetails from "./pages/item-details-page";

function App() {
  return (
    <div className="App">
      <Router>
        <DataProvider>
          {/* page routes */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/items" element={<ItemsPage gender={null} />} />
            <Route path="/items-men" element={<ItemsPage gender="male" />} />
            <Route
              path="/items-women"
              element={<ItemsPage gender="female" />}
            />
            <Route path="/item-details/:id" element={<ItemDetails />} />
            {/* Add more routes as needed */}
          </Routes>
        </DataProvider>
      </Router>
      <StylistChat />
    </div>
  );
}

export default App;
