import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchHeroImage, fetchClothingItems } from "../services/strapi-calls";

// Create the context
const DataContext = createContext();

// Create a provider component that manages multiple data sources
export const DataProvider = ({ children }) => {
  const [heroData, setHeroData] = useState({
    heroImageUrl: "",
    title: "",
    subtitle: "",
  });

  const [items, setItems] = useState([]);

  useEffect(() => {
    // Function to load the hero image data
    const loadHeroImage = async () => {
      try {
        const data = await fetchHeroImage();
        setHeroData({
          heroImageUrl: data.attributes.image.data.attributes.url,
          title: data.attributes.title,
          subtitle: data.attributes.subtitle,
        });
      } catch (error) {
        console.error("Error loading hero data:", error);
      }
    };

    loadHeroImage();
  }, []);

  useEffect(() => {
    // Function to load clothing items data
    const loadClothingItems = async () => {
      try {
        const data = await fetchClothingItems();
        const fetchedItems = data.map((item) => ({
          id: item.id,
          brand: item.attributes.brand,
          title: item.attributes.title,
          price: item.attributes.price,
          gender: item.attributes.gender,
          description: item.attributes.description,
          images:
            item.attributes.images?.data?.map(
              (image) => image.attributes.url
            ) || [], // Get all images
        }));
        setItems(fetchedItems);
      } catch (error) {
        console.error("Error loading clothing items:", error);
      }
    };

    loadClothingItems();
  }, []);

  return (
    <DataContext.Provider value={{ heroData, items }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hooks for accessing data
export const useHeroData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error("useHeroData must be used within a DataProvider");
  }
  return context.heroData;
};

export const useItemsData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error("useItemsData must be used within a DataProvider");
  }
  return context.items;
};
